import '../style/emotions-flow-landing.scss';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

document.addEventListener('DOMContentLoaded', () => {
  // ********************** Video model-66 *******************************

  const playButton = document.querySelector('.model-66__btn-play');
  const modal = document.querySelector('.model-66__modal');
  const closeButton = document.querySelectorAll('#close-modal-video-flow');
  const videoContainer = document.getElementById('md-video-flow');
  const videoId = document.querySelector('.model-66__modal--video').getAttribute('data-video');

  if (playButton) {
    playButton.addEventListener('click', (e) => {
      e.preventDefault();
      modal.classList.remove('d-none');
      document.documentElement.classList.add('noscroll');
      videoContainer.innerHTML = `<iframe src="https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1" frameborder="0" allowfullscreen></iframe>`;
    });
  }

  closeButton.forEach(button => {
    button.addEventListener('click', () => {
      modal.classList.add('d-none');
      document.documentElement.classList.remove('noscroll');
      videoContainer.innerHTML = '';
    });
  });

  modal.addEventListener('click', (e) => {
    if (e.target === modal) {
      modal.classList.add('d-none');
      document.documentElement.classList.remove('noscroll');
      videoContainer.innerHTML = '';
    }
  });

  // ************************ end modal video ************************

  // ************************ Model video 72 mute and unmute ************************
  const video = document.querySelector('.model-72 video');
  const muteButton = document.getElementById('mute-btn');
  const unmuteButton = document.getElementById('unmute-btn');

  const toggleIcons = (isMuted) => {
    if (isMuted) {
      muteButton.classList.remove('d-none');
      unmuteButton.classList.add('d-none');
    } else {
      unmuteButton.classList.remove('d-none');
      muteButton.classList.add('d-none');
    }
  };

  const toggleMute = () => {
    toggleIcons(video.muted);
    video.muted = !video.muted;
  };

  document.querySelector('[data-button="toggle-mute"]')?.addEventListener('click', toggleMute);
  video.addEventListener('volumechange', () => {
    toggleIcons(video.muted);
  });

  // ************************ end model video 72 mute and unmute ************************

  document.querySelectorAll('.polaroid__parallax').forEach((parallaxContainer) => {
    gsap.registerPlugin(ScrollTrigger)
    const parallaxAnimation = gsap.fromTo(parallaxContainer.querySelectorAll('.model-polaroid'), {
      y: (index, target) => target.dataset?.parallaxFrom || 100,
    }, {
      y: (index, target) => target.dataset?.parallaxTo || -100,
      ease: 'power1.inOut',
    });

    ScrollTrigger.create({
      animation: parallaxAnimation,
      trigger: parallaxContainer,
      start: 'top bottom',
      end: 'bottom top',
      scrub: 1,
    })
  })

});
